import { ref } from "vue";

const getOptions = () => {

    const options = ref([1,2,3,4,5,6,7,8,9,10]);



    return {
        options,
    }
}

export default getOptions